@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounceIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

/* ------------------------------------HOME PAGE----------------------------------------- */
.heroimg {
  max-width: 100%;
  height: 770px;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.7s;
  transition-property: opacity;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 0s 0.7s;
  transition-duration: 0.7s;
}
.carousel-fade .carousel-item.active {
  transition-duration: 0.7s; /* Adjust fade-out duration in seconds */
  transition-timing-function: ease-in-out;
}

.thick-dot {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #c90412;
  border-radius: 50%;
}
.lhs {
  width: 80%;
  margin-bottom: 10%;
}
.lhsmobile {
  width: 80%;
  margin-bottom: 10%;
}
.lhsheading {
  padding: 10px 12px;
  border-radius: 40px;
  background-color: #e6e6e6;
  font-family: Urbanist;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  max-width: fit-content;
  color: #929292;
}
.lhssubtitle {
  font-family: Urbanist;
  font-size: 74px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
}
.rhs {
  margin-left: auto;
  width: 53%;
  text-align: left;
}
.rhsdesc {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
  padding-bottom: 10px;
}
/*  -------------------------HOME SEGMENT 1-----------------*/
.homeSegment1aboutus {
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  text-transform: uppercase;
  color: #c90412;
}
.homeSegment1aboutusline {
  border-bottom: 1.7px solid #c90412;
  width: 40px;
  margin: 10px 0;
  margin-top: 7px;
}
.homeSegment1text {
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.horizontal-border-line {
  border-bottom: 2px solid #ebebeb;
  width: 100%;
}
/*  -------------------------HOME SEGMENT 2-----------------*/
.homeSegment2 {
  grid-template-columns: auto auto;
  gap: 40px;
}
.homeSegment2container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 140px;
}
.homeSegment2numbers {
  font-family: Urbanist;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
}
.homeSegment2subtext {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: left;
  color: #929292;
}
.homeSegment2textContainer {
  padding-right: 300px;
}
.homeSegment2text {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}
.homeSegment2contactcontainer {
  justify-content: space-between;
  display: flex;
}
.homeSegment2contact {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #c90412;
}
.homeSegment2ccontact {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #b1d9b1;
}
.homeSegment2textContainerline {
  border-bottom: 2px solid #b1d9b1;
  width: 100%;
}
.HomeSegment2IMG {
  width: 90%;
  height: 305px;
}
/*  -------------------------HOME SEGMENT 3-----------------*/

.homeSegment3 {
  background-color: #f6f6f6;
}
.homeSegment3container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}
.homeSegment3intro {
  font-family: Urbanist;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  text-align: left;
}
.homeSegment3box {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  /* animation: slideInFromLeft 5s ease-in-out; */
}
.homeSegment3box2 {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  /* animation: slideInFromRight 5s ease-in-out; */
}
.slideLeft {
  /* animation: slideInFromLeft 5s ease-in-out; */
}
.slideRight {
  /* animation: slideInFromRight 5s ease-in-out; */
}
.homeSegment3img {
  width: 100%;
  height: 350px;
  padding-bottom: 20px;
}
.homeSegment3title {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
}
.homeSegment3subtitle {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}

.homeSegment3contactcontainer {
  display: flex;
}
.homeSegment3imgcontainer {
  text-align: center;
}
/*  -------------------------HOME SEGMENT 4-----------------*/
.homeSegment4 {
}
.homeSegment4container {
  background-color: #1c759e;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
}
.homeSegment4title {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  color: white;
}
.homeSegment4subtitle {
  color: #e7e7e7;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.homeSegment4contactUs {
  padding: 13px 17px;
  border-radius: 50px;
  background-color: white;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: black !important;
}
.homeSegment4contactUs:hover {
  color: #1c759e !important;
}
/*  -------------------------HOME SEGMENT 5-----------------*/
.homeSegment5container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}
.homeSegment5IMG {
  width: 100%;
  border-radius: 12px;
}
.homeSegment5container2 {
  display: block !important;
}
.homeSegment5box {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  padding: 40px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
}
.homeSegment5title {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}
.homeSegment5subtitle {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.homeSegment6container {
  justify-content: space-between;
}
.testimonialbtn {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #e7e7e7;
  background-color: #1c759e;
  border-radius: 30px;
  padding: 10px 16px;
  border-color: transparent;
}
.homeSegment6testimoialarrow {
  background-color: white;
  border-radius: 50%;
  padding: 3px;
}

.testimonialtitle {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
}
.testimonialparagraph {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.testimonials {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
}
.testimonial {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  padding: 30px;
  border-radius: 12px;
  /* animation: fadeIn 1.5s ease-in-out; */
}
.testimonialperson {
  gap: 15px;
}
.testimonialimg {
  width: 80px;
  height: 75px;
  border-radius: 50%;
}
.testimonialname {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
}
.testimonialplace {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #999999;
}
.testimonialrating img {
  width: 50px;
}
/* --------------HOME SEGMENT 7--------- */
.homeSegment7 {
  background-color: #f6f6f6;
}
.FAQs {
  background-color: #f6f6f6;
}
.FAQ {
}
.FAQNo {
  font-family: Urbanist;
  font-size: 52px;
  font-weight: 700;
  text-align: left;
}
.faqanswertitle {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}
.accordion-button {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  text-decoration: none;
}

.accordion-body {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  color: #414141;
  padding: 0px 190px;
}
.accordion-item {
  border: none !important;
}
.accordion-item:focus {
  border: none !important;
}
.accordion-collapse {
  border: none !important;
}
.accordion-collapse:focus {
  border: none !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
/* --------------HOME SEGMENT 8--------- */
.homeSegment8 {
  text-align: center;
  margin: auto;
  background-blend-mode: multiply; /* Experiment with other blend modes */
}
.homeSegment8title {
  font-family: Urbanist;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
  color: rgba(231, 231, 231, 1);
  margin: auto;
  padding-top: 150px;
}
.homeSegment8subtitle {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: center;
  color: rgb(215, 214, 214);
}
.emailform {
  margin: auto;
}
.emailinput {
  padding: 20px 20px 20px 10px;
  border: none;
  width: 400px;
}
.email-container {
  background-color: white;
  margin: auto;
  border-radius: 100px;
  width: 600px;
  padding: 4px;
}
input::placeholder {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
input[type="email"]:focus {
  outline: none;
  border: none;
}
.homeSegment8::after {
  content: ""; /* Empty content to create the element */
  position: absolute; /* Make it absolute for full coverage */
  bottom: 0; /* Position it at the bottom */
  left: 0;
  right: 0; /* Stretch it horizontally across the image */
  height: 50px; /* Adjust height for desired fade length */
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Create the fade effect */
}

/* --------------------FOOTER------------------- */
.footersection {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
}
.tellink {
  color: #414141db;
}
.contactfooterwhatsapp {
  font-size: 25px;
}
.logodesc {
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.social-media-icons {
  display: flex;
  gap: 13px;
  font-size: 15px;
}
.social-media-icon {
  padding: 7px 12px;
  color: white;
  background-color: #414141;
  border-radius: 50%;
}
.footerlogoo {
  width: 200px;
  height: 112px;
}
.footersection2title {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.footerarrow {
  color: #c90412;
}
.footersection2link {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  color: #414141db;
  text-align: left;
  padding-bottom: 15px;
}
.copyright {
  justify-content: space-between;
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #414141;
}

/* ----------------------ABOUT US PAGE---------------- */
.aboutsegment1 {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}

.aboutheroimg {
  height: 800px;
}
.missionbox {
  /* animation: slideInFromLeft 2s ease-in-out; */
}
.visionbox {
  /* animation: slideInFromRight 2s ease-in-out; */
}
.missionlist {
  font-size: 20px;
}
.aboutsegment2desc {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  width: 539px;
}
.aboutsegment2iidesc {
  text-align: left;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.6px;
}
.valuesheading {
  font-family: Urbanist;
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}
.valuesdesc {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.valuessubheading {
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
}
.flexii {
  justify-content: end;
}
.homeSegment3introii {
  text-align: right;
}
.aboutsegment2 {
  color: #f5f5f5cb;
  gap: 150px;
  display: grid;
  grid-template-columns: auto auto;
}
.aboutsegment3 {
  background-color: #f6f6f6;
}
.aboutsegment3container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
}
.aboutsegment3box {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  padding: 25px 20px;
  border-radius: 5px;
  /* animation: bounceIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  /* animation: fadeIn 1.5s ease-in-out; */
}
.aboutsegment3icon {
  color: #1c759e;
  font-size: 30px;
}
.aboutsegment3boxtitle {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}
.aboutsegment3boxdesc {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.flowchart {
  width: 100%;
  padding: 0px 50px;
}
.greybg {
  background-color: #f6f6f6;
}
.vacancydiv {
}
.vacancy {
  width: 100%;
}
.aboutsegment3box {
  background-color: white;
}
.jobsegment2title {
  font-family: Urbanist;
  font-size: 42px;
  font-weight: 700;
  line-height: 58.8px;
  text-align: left;
}
.jobsegment2box {
  box-shadow: 0px 1px 5px 0px #aaaaaa40;
  background-color: white;
  border-radius: 5px;
}
.jobsegment2sub1 {
  justify-content: space-between;
}
.jobtitle {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
}
.jobsegment2desc {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  width: 726px;
}
.jobsegment4 {
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.backbutton {
  font-family: Urbanist;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
}
.jobdescsegment1title {
  font-family: Urbanist;
  font-size: 42px;
  font-weight: 700;
  line-height: 58.8px;
  text-align: left;
}
.jobdescsegment1subtitle {
  font-family: Urbanist;
  font-size: 27px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}
.jobdescsegment2heading {
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}
.contactheading {
  font-family: Urbanist;
  font-size: 42px;
  font-weight: 700;
  line-height: 58.8px;
  text-align: left;
}
.contactsubheading {
  font-family: Urbanist;
  font-size: 19px;
  font-weight: 500;
  text-align: left;
}
.contacticoncontainer {
  padding: 7px;
  border-radius: 50px;
  border: 1px solid #c3c1c1;
  max-width: fit-content;
}
.contacticon {
  color: white;
  background-color: #1c759e;
  border-radius: 50%;
  padding: 10px;
}
.contactmail {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;
  
  color: black !important;
}
.contacttempimage {
  height: 500px;
}
.contacticons {
  gap: 13px;
  font-size: 21px;
  justify-content: center;
}
.bluetext {
  color: #28a5df;
  font-weight: 500;
}
.blacktext {
  color: black;
  font-weight: 500;
}
/* -------------NAVBAR------------- */
.navbar {
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f5f5;
}
.nav-link {
  position: relative;
}
.nav-link::after {
  content: "";
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: #1c759e;
  transition: width 0.3s;
}
.nav-link:hover::after {
  width: 30%;
}
.missionwhite {
  color: #fff !important;
}
.missionlinewhite {
  border-bottom: 1.7px solid #fff;
}
.navlogo {
  width: 102px;
  height: 67px;
}
.navenvelope {
  color: #414141;
  background-color: white;
  border-radius: 50%;
  padding: 7px;
  font-size: 14px;
}
.nav-link {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
a {
  text-decoration: none;
}
a:hover {
  color: #bcbbbb;
}
.contactformflex {
  gap: 20px;
}
label {
  display: block;
}
.formgroup {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
}
.partners {
  grid-template-columns: auto auto auto;
}
.partner {
  font-family: Urbanist;
  text-align: center;
  font-size: 23px;
  font-weight: 500;
}
.partnerimg {
  width: 350px;
}
.formgroupsection label {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
}
.formgroupsection input {
  height: 50px;
  border-color: transparent;
  border-radius: 5px;
  padding: 10px;
}
.maxwidth {
  width: 100%;
}
.aboutheroreplacementbox {
  padding-top: 300px;
  padding-bottom: 100px;
}
.lhssubtitle {
  color: #fff;
}
.complaintpolicylink {
  color: black;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
  text-decoration: underline;
}
.contactform {
  padding: 40px 30px;
  background-color: #eaf0f3;
  border-radius: 9px;
}
textarea {
  width: 100%;
  border-color: transparent;
  border-radius: 5px;
  height: 160px;
  padding: 10px;
}
.contactformbtn {
  text-align: center;
}
.formgroupsection input::placeholder {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}

/* -----------------------MOBILE VIEW---------------------- */
@media only screen and (max-width: 1025px) {
  .nav-link::after {
    content: "";
    height: 0px;
  }
  .homeSegment3container {
    grid-template-columns: auto;
  }
  .homeSegment5container {
    grid-template-columns: auto;
  }
  .testimonials {
    grid-template-columns: auto;
  }
  .testimonial {
    padding: 30px;
  }
  .partner {
    font-size: 16px;
    font-weight: 500;
  }
  .partnerimg {
    width: 250px;
  }
  .partners {
    text-align: center;
  }
  .footersection {
    grid-template-columns: auto;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .homeSegment2container {
    gap: 20px;
  }
  .homeSegment3img {
    width: 100%;
    height: auto;
  }
  .homeSegment5IMG {
    width: 100%;
    height: auto;
  }
  .HomeSegment2IMG {
    width: 100%;
    height: auto;
  }
  .homeSegment4container {
    display: block;
  }
  .email-container {
    width: 100%;
  }
  .lhssubtitle {
    font-size: 32px;
    line-height: 40px;
  }
  .heroimg {
    height: 700px;
  }

  .lhs {
    width: 100%;
  }
  .lhsmobile {
  }
  .rhs {
    width: 100%;
  }
  .carousel-caption {
    right: 0%;
    left: 0%;
    padding: 0 20px 50px 20px;
    text-align: left;
  }
  .lhssubtitle {
    font-size: 30px;
    padding-bottom: 10px;
    color: #fff;
  }
  .lhsheading {
    font-size: 9px;
    padding: 6px 13px;
  }
  .aboutheroreplacementbox {
    padding-top: 250px;
    padding-bottom: 40px;
  }

  .homeSegment1text {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
  }
  .homeSegment2container {
    gap: 0px;
  }
  .homeSegment2textContainer {
    padding-right: 0;
  }
  .homeSegment2text {
    order: 2 !important;
  }
  .homeSegment2order1 {
    order: 1 !important;
  }
  .homeSegment2order2 {
    order: 2 !important;
  }
  .homeSegment2 {
    grid-template-columns: auto;
  }
  .emailinput {
    width: 100%;
  }
  .navlogo {
    width: 97px;
    height: 62px;
  }
  .homeSegment2numbers {
    font-family: Urbanist;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;
  }
  .homeSegment2subtext {
    font-family: Urbanist;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
  .homeSegment3intro {
    font-family: Urbanist;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;
  }
  .homeSegment3intro br {
    display: none;
  }
  .homeSegment3subtitle {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .homeSegment3title {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .homeSegment4title {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }
  .homeSegment4subtitle {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
  }
  .homeSegment4container {
    text-align: center;
    padding-bottom: 20px;
  }
  .homeSegment1aboutus {
    font-family: Urbanist;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #c90412;
  }
  .homeSegment5title {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .homeSegment5subtitle {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .testimonialrating img {
    width: 32px;
    height: 32px;
  }
  .testimonialparagraph {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .testimonialbtn {
    font-size: 15px;
  }
  .testimonialtitle {
    font-family: Urbanist;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .FAQs div {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .FAQNo {
    font-family: Urbanist;
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    text-align: left;
  }
  .faqanswertitle {
    font-family: Urbanist;
    font-size: 17px;
    font-weight: 700;
    line-height: 25.2px;
    text-align: left;
  }
  .accordion-body {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding-left: 20px !important ;
    padding-right: 20px !important;
  }
  .accordion-collapse.accordion-body {
    padding-left: 20px !important ;
    /* padding-right: 20px !important; */
  }
  .homeSegment8 {
    height: auto !important;
  }
  .homeSegment8title {
    padding-top: 30px;
    font-family: Urbanist;
    font-size: 28px;
    font-weight: 600;
    line-height: 39.2px;
    text-align: center;
  }
  .homeSegment8subtitle {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
  .homeSegment8subtitle br {
    display: none;
  }
  .homeSegment8 {
    padding: 30px;
  }
  .email-container {
    display: flex;
  }
  .email-container input[type="email"]::placeholder {
    font-family: Urbanist;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }
  .footertestimonialbtn {
    display: flex;
    padding: 5px 10px;
    font-size: 15px;
  }
  .homeSegment6testimoialarrow {
    width: 22px;
  }
  .emailinput {
    padding: 0 10px;
    border-radius: 50px;
  }
  .footersection {
    gap: 20px;
  }
  .social-media-icons {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .logodesc {
    padding-top: 10px !important;
  }
  .footersection2links {
    padding-top: 12px !important;
  }
  .footersection2link {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding-bottom: 7px !important;
  }
  .logodesc {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .footersection2title {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
  }
  .copyright {
    font-family: Urbanist;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
  }
  .footerlogo {
    width: 72px;
    height: 52px;
  }
  .footerhomeSegment6testimoialarrow {
    width: 25px;
  }
  .lhssubtitle {
    padding-top: 5px;
  }
  .mobile-carousel-caption {
    top: 23rem !important;
  }
  .aboutsegment3container {
    grid-template-columns: auto;
    gap: 20px;
  }
  .aboutsegment2desc {
    width: 100%;
  }
  .aboutsegment1 {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
  .aboutsegment2desc {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
  }
  .aboutsegment2 {
    grid-template-columns: auto;
    gap: 20px;
    padding-top: 10px !important;
  }
  .aboutsegment2iidesc {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .missionlist {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
  .valuesdesc {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .abouthereforyou {
    padding-top: 30px;
  }
  .valuessubheading {
    font-size: 18px;
  }
  .valuesheading {
    font-family: Urbanist;
    font-size: 24px;
    font-weight: 700;
  }
  .flexii {
    justify-content: left;
  }
  .aboutsegment3boxtitle {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .aboutsegment3boxdesc {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .aboutheroimg {
    width: 100%;
    height: 500px !important;
  }
  .homeSegment6container {
    display: block !important;
  }
  .homeSegment2cont {
    padding: 20px;
  }
  .jobsegment2desc {
    width: 100%;
  }
  .jobsegment2sub1 {
    display: block !important;
  }
  .jobsegment2title {
    font-family: Urbanist;
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    text-align: left;
  }
  .jobtitle {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .jobsegment2desc {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .jobsegment4 {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
  }
  .contactheading {
    font-family: Urbanist;
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    text-align: left;
  }
  .contactsubheading {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
  }
  .contactmail {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: center;
  }
  .contacttempimage {
    padding-top: 20px;
  }
  .jobdescsegment1title {
    font-family: Urbanist;
    font-size: 25px;
    font-weight: 700;
    line-height: 39.2px;
    text-align: left;
  }
  .jobdescsegment1subtitle {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: left;
  }
  .jobdescsegment2list {
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .jobdescsegment2heading {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .navbar-toggler-icon {
    width: 46.65px;
    height: 60px;
  }
  .navbar-toggler {
    padding: 0 !important;
    border: none !important;
    outline: none !important;
  }
  .navbar-toggler:focus {
    border: none !important;
    outline: none !important;
    border-color: transparent;
  }
  .navbar-toggler:active {
    border: none !important;
    outline: none !important;
  }
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }
  .formgroup {
    display: block;
  }
  .mobileinput {
    width: 100%;
  }
  .complaintpolicylink {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  /* .lhsmobile{
    margin-bottom: 10% !important;
  } */
}

.section1 {
  padding: 150px 50px 100px 50px;
  display: grid;
  grid-template-columns: auto auto;
}
.section1exp {
  font-family: Urbanist;
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
}
.section1expblue {
  color: #28a5df;
}
.section1paragraph {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  color: #414141;
}
.section1listname {
  font-family: Urbanist;
  font-size: 19px;
  font-weight: 500;
  text-align: left;
  color: #414141;
}
.section1quote {
  font-family: Urbanist;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #414141;
}
.section1galleryi {
  display: flex;
  grid-template-columns: auto auto;
  gap: 10px;
}
.section1galleryii {
  display: flex;
  grid-template-columns: auto auto;
  gap: 10px;
}
.section2 {
  background-color: #f6f6f6;
  gap: 30px;
  display: grid;
  grid-template-columns: auto auto;
}
.lhsheading2 {
  color: #c90412;
  background-color: #ffd6d9;
}
.section2paragraph {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.section2missionbox {
  background-color: white;
}
.section2missionpara {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  background-color: #f2f2f2;
}
.section2mission {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
}
.section2exp {
  font-family: Urbanist;
  font-size: 42px;
  font-weight: 600;
  text-align: left;
}
.section3 {
  background-color: #f6f6f6;
}
.section3headingcontainer {
  gap: 30px 170px;
  display: grid;
  grid-template-columns: auto auto;
}
.section3headingpara {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.section4 {
  background-color: #f6f6f6;
}
.section4heading {
  margin: 0 auto;
}
.section4headingi {
  margin: 0 auto;
}
.section4headingii {
  text-align: center;
}
.section4grid {
  gap: 30px;
  display: grid;
  grid-template-columns: auto auto;
}
.section6{
  display: grid;
  grid-template-columns: auto auto;
  gap: 300px;
}
.section6heading{
  color: white;

}
.section6para{
font-family: Urbanist;
font-size: 18px;
font-weight: 400;
line-height: 25.2px;
text-align: left;
color: white;
}
.section7 {
  background-color: #f6f6f6;
}
.section7heading {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}

@media only screen and (max-width: 1025px) {
 .section1{
  padding: 100px 30px 10px 30px;
  grid-template-columns: auto;
 } 
 .section1exp{
  font-family: Urbanist;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
 }
 .section1quote{
  padding-bottom: 20px;
 }
 .section1paragraph{
  font-size: 16px;
 } 
 .section1listname {
  font-size: 16px;
 }
 .section2{
  grid-template-columns: auto;
 }
 .section2exp{
  padding-top: 10px;
  font-size: 25px;
  line-height: 39px;
 }
 .section2paragraph {
  font-size: 16px;
  line-height: 22px;
 }
 .aboutsegment3boxtitle {
  font-size: 17px;
 }
 .section3headingcontainer{
  gap: 15px;
  grid-template-columns: auto;
 }
 .section4grid{
  grid-template-columns: auto;
 }
 .section6{
  gap: 10px;
  grid-template-columns: auto;
 }
 .section1galleryi{
 }
 .section1galleryii{
 }
 .lpimg{
  width: 100% !important;
 }
 .section6para{
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.2px;
  text-align: left;
  color: white;
  }
  .section2exp{
    font-size: 18px;
    line-height: 23px;
  }
}
